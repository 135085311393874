import React from "react";
import NewsItem from "../main/NewsItem";
import Notice0 from "../res/newsImage/notice.png";
import Notice04 from "../res/newsImage/notice4.png";
import Notice03 from "../res/newsImage/notice3.png";
import Notice05 from "../res/newsImage/notice5.png";
import Notice06 from "../res/newsImage/notice6.png";
import Notice07 from "../res/newsImage/notice7.png";
import Event05 from "../res/newsImage/event5.jpg";
import Event06 from "../res/newsImage/event6.jpg";
import { useTranslation } from "react-i18next";
import I18next from "i18next";

export default function NewsList() {
  const { t } = useTranslation();

  const news = [
    {
      image: Notice07,
      // title: "인도네시아 RSI Sultan Agung Semarang 병원과 MOU 체결",
      // desc: "마이허브는 인도네시아 RSI Sultan Agung Semarang 병원과 의료 AI 솔루션 도입을...",
      title: I18next.t("mainNew-notice-title-t7"),
      desc: I18next.t("mainNew-notice-desc-t7"),
      date: "2025, 02",
      link: "https://m.healthinnews.co.kr/view.php?ud=2025021811262877596aa9cc43d0_48",
    },
    {
      image: Notice0,
      // title: "마이허브, 43억원 시리즈 A 투자 유치 성공",
      // desc: "마이허브는 43억원 규모 시리즈 A 투자 유치에성공했다. 이번 투자 라운드는 마이허브의 기술력과 성장 가능성을 인정받아 성사됐으며, 한국투자파트너스, 하나벤처스 등 주요 투자사가 참여했다. ",
      title: I18next.t("mainNew-notice-title-t6"),
      desc: I18next.t("mainNew-notice-desc-t6"),
      date: "2025, 01",
      link: "http://www.bokuennews.com/news/article.html?no=254439",
    },

    {
      image: Event06,
      // title: "말레이시아 NCI(National Cancer Institue)와 협업 준비",
      // desc: "마이허브는 최근 말레이시아 국립 암 센터(NCI)에 중요한 발표의 장을 열었습니다. 이번 프리젠테이션에서는 마이허브의 주요 솔루션인 마이링크를 말레이시아 국립 암 센터의 의료진을 대상으로 소개했습니다.",
      title: I18next.t("mainNew-event-title-t4"),
      desc: I18next.t("mainNew-event-desc-t4"),
      date: "2024, 07",
      link: "https://www.linkedin.com/posts/maihub_introducing-maihub-to-malaysias-national-activity-7223973096607641603-VC_u?utm_source=share&utm_medium=member_desktop",
    },

    // {
    //   image: Notice05,
    //   // title: "마이허브 중기부 TIPS 선정",

    //   title: I18next.t("mainNew-notice-title-t5"),
    //   desc: I18next.t("mainNew-notice-desc-t5"),
    //   date: "2024, 06",
    //   link: "https://www.linkedin.com/posts/maihub_maihub-mailink-ai-activity-7209098526629216257-o9AP?utm_source=share&utm_medium=member_desktop",
    // },

    // {
    //   image: Notice06,
    //   // title: "마이허브 왕진연구소와 MOU 체결",
    //   // desc: "마이허브는 최근 왕진연구소와 국내 의료분야 사업 협력을 위한 MOU를 체결했습니다. 이번 협약을 통해 양 기관은 방문진료 사업 추진, 의료분야 신규 사업 발굴, 그리고 의료 인공 지능 솔루션 개발 등 다양한 분야에서 협력할 예정입니다.",
    //   title: I18next.t("mainNew-notice-title-t4"),
    //   desc: I18next.t("mainNew-notice-desc-t4"),
    //   date: "2024, 06",
    // },

    // {
    //   image: Notice04,
    //   // title: "'maiLink' 말레이시아 MDA 인증 획득",
    //   // desc: "'maiLink'가 말레이시아 의료기기청으로부터 MDA인증을 획득했습니다. 이번 인증은 maiLink'가 의료기기로서의 안전성과 효과성을 공식적으로 인정받았음을 의미합니다.",
    //   title: I18next.t("mainNew-notice-title-t3"),
    //   desc: I18next.t("mainNew-notice-desc-t3"),
    //   date: "2024, 03",
    //   link: "http://www.bokuennews.com/news/article.html?no=241708",
    // },

    // {
    //   image: Event05,
    //   // title: "마이허브 2024년 3월 개최된 'KIMES 2024'에 참가",
    //   // desc: "고객 맞춤형 메디컬 AI 플랫폼 ‘마이링크’를 선보였습니다. “AI 보급 확대로 의료환경 선진화에 기여!”",
    //   title: I18next.t("mainNew-event-title-t3"),
    //   desc: I18next.t("mainNew-event-desc-t3"),
    //   date: "2024, 03",
    //   link: "https://kr.aving.net/news/articleView.html?idxno=1788079",
    // },

    // {
    //   image: Notice03,
    //   // title: "마이허브 이지스헬스케어와 MOU 체결",
    //   // desc: "마이허브는 '이지스전자차트' 활용을 위한 전략적 파트너십을 체결하고, 고객이 의료 AI 솔루션을 보다 편리하게 활용할 수 있도록 지원할 예정입니다.",
    //   title: I18next.t("mainNew-notice-title-t2"),
    //   desc: I18next.t("mainNew-notice-desc-t2"),
    //   date: "2024, 02",
    //   link: "http://www.hitnews.co.kr/news/articleView.html?idxno=52175",
    // },
  ];

  return (
    <div className="Thumb-container">
      {news.map((news) => (
        <NewsItem
          NewsImage={news.image}
          NewsTitle={news.title}
          NewsDescription={news.desc}
          NewsDate={news.date}
          link={news.link}
        />
      ))}
    </div>
  );
}
