import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom"; 

const OpenPage = () => {
  const [showMessage, setShowMessage] = useState(false);

  let [searchParams] = useSearchParams(); // URL 파라미터 가져오기

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // const rawJson = searchParams.get("data");
    // console.log(rawJson);


    // const androidAppUrl = "maireport://open";
    // const appUrl = `maireport://open?data=${rawJson}`;
    const playStoreIntentUrl =
      "intent://play.google.com/store/apps/details?id=com.maihub.maireport#Intent;scheme=https;package=com.android.vending;end;";
    const playStoreWebUrl = "https://play.google.com/store/apps/details?id=com.maihub.maireport";
    const iosAppUrl = "maireport://open";
    const appStoreUrl = "https://apps.apple.com/kr/app/maireport/id6742101122";

    let isAppOpened = false;
    let startTime = new Date().getTime();

    if (/android/i.test(userAgent)) {
      // ✅ Google Play 스토어 앱에서 바로 열기
    //   window.location.href = appUrl;//playStoreIntentUrl;
      window.location.href = playStoreIntentUrl;//playStoreWebUrl;

      // ✅ Play Store 앱이 없을 경우 웹으로 이동
    //   setTimeout(() => {
    //     window.location.href = playStoreIntentUrl;//playStoreWebUrl;
    //   }, 2000);
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        window.location.href = appStoreUrl;
    //    window.location.href = appUrl;
      // ✅ iOS: App Store로 이동
    //   setTimeout(() => {
    //     window.location.href = appStoreUrl;
    //   }, 2000);
    } else {
      // ✅ PC 환경에서는 안내 메시지 표시
      setShowMessage(true);
    }

    // ✅ 앱 실행 여부 감지
    const visibilityChangeHandler = () => {
        if (document.hidden) {
          isAppOpened = true; // 앱이 실행되었다고 판단
        }
      };

    document.addEventListener("visibilitychange", visibilityChangeHandler);

    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };

  }, []);

  return (
    <div>
      <h2>maiReport 앱 다운로드</h2>
      {showMessage ? (
        <p>이 페이지에서는 앱을 실행할 수 없습니다.  
          <a href="https://play.google.com/store/apps/details?id=com.maihub.maireport">Play Store</a> 또는  
          <a href="https://apps.apple.com/kr/app/maireport/id6742101122">App Store</a>에서 앱을 다운로드하세요.</p>
      ) : (
        <p>앱 다운로드 중...</p>
      )}
    </div>
  );
};

export default OpenPage;
