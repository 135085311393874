import { useEffect } from "react";

const APP_STORE_URL = "https://apps.apple.com/kr/app/maireport/id6742101122"; // 앱스토어 URL
const UNIVERSAL_LINK = "maireport://open"; // 유니버설 링크

export default function OpenPage1() {
  useEffect(() => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const storeLink = isIOS ? APP_STORE_URL : "https://play.google.com/store/apps/details?id=your.package.name";
    
    let hiddenTime = Date.now();

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // 사용자가 돌아온 경우 (앱 실행 실패 가능성이 높음)
        const elapsed = Date.now() - hiddenTime;
        if (elapsed < 1500) {
          window.location.href = storeLink;
        }
      } else {
        // 앱으로 이동했다고 가정하고 시간 기록
        hiddenTime = Date.now();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // iframe을 사용하여 앱 실행 시도
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = UNIVERSAL_LINK;
    document.body.appendChild(iframe);

    // 일정 시간 후 iframe 삭제 및 앱스토어 이동
    const timeout = setTimeout(() => {
      window.location.href = storeLink;
    }, 2000);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (iframe.parentNode) {
        iframe.parentNode.removeChild(iframe);
      }
    };
  }, []);

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h2>앱을 확인하는 중입니다...</h2>
      <p>앱이 설치되어 있으면 자동으로 실행됩니다.</p>
      <p>설치되어 있지 않으면 앱스토어로 이동합니다.</p>
    </div>
  );
}
